import { createTheme } from '@mui/material'

export default createTheme({
  palette: {
    primary: {
      main: '#099f92',
      light: '#71CCC3',
      dark: '#007064',
      contrastText: '#fff',
    },
    secondary: {
      main: '#c17677',
      light: '#ffd8d7',
      dark: '#c17677',
      contrastText: '#fff',
    },
  },
  typography: {
    allVariants: {
      color: '#003847',
    },
  },
})

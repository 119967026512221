import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useAsync } from '@react-extra/hooks'
import { useNavigate } from 'react-router-dom'
import api, {
  getLocalStorageTokens,
  setLocalStorageTokens,
} from '../../utils/api'
import FullPageSpinner from '../../components/FullPageSpinner'
import FullPageErrorFallback from '../../components/FullPageErrorFallback'

const AuthContext = createContext()
AuthContext.displayName = 'AuthContext'

export const roles = {
  client: 'Client',
  consultant: 'Consultant',
  admin: 'Admin',
  employee: 'Employee',
}

export const accountStatus = {
  incomplete: { id: 0, label: 'incomplet' },
  valid: { id: 1, label: 'Validé' },
  new: { id: 2, label: 'Nouveau' },
  reviewing: { id: 3, label: 'En révision' },
  refused: { id: 4, label: 'Refusé' },
  updated: { id: 5, label: 'Mis a jour' },
}

const getAccountData = async () => {
  let account = null
  const tokens = getLocalStorageTokens()

  if (tokens) {
    const {
      roles: ServerRoles,
      email,
      validataionState: status,
      picLink,
    } = await api('Account/Me')

    // define account type
    let type
    if (ServerRoles.includes(roles.admin)) {
      type = roles.admin
    } else if (ServerRoles.includes(roles.client)) {
      type = roles.client
    } else if (ServerRoles.includes(roles.employee)) {
      type = roles.employee
    } else {
      type = roles.consultant
    }

    account = {
      type,
      email,
      status,
      picLink: picLink
        ? `${process.env.REACT_APP_AWS_API}/${picLink}`
        : picLink,
    }
  }

  return account
}

export function AuthProvider(props) {
  const {
    run,
    isLoading,
    isIdle,
    isError,
    error,
    setData,
    data: account,
  } = useAsync()

  const navigate = useNavigate()

  const register = useCallback(({ email, password, role }) => {
    if (!role || !Object.values(roles).includes(role)) {
      throw new Error('Incorrect role!')
    }

    if (role === roles.client) {
      return api('Account/registerClient', {
        data: { email, password },
        isPrivate: false,
      })
    }

    return api('Account/registerConsultant', {
      data: { email, password },
      isPrivate: false,
    })
  }, [])

  const validateAccount = useCallback(
    ({ email, code }) =>
      api(`Account/ConfirmEmailCode?email=${email}&code=${code}`, {
        isPrivate: false,
      }),
    [],
  )

  const login = useCallback(
    async ({ email: username, password }) => {
      const tokens = await api('Token/CreateToken', {
        isPrivate: false,
        data: { username, password },
      })

      setLocalStorageTokens(tokens)

      const data = await getAccountData()
      setData(data)
    },
    [setData],
  )

  const forgetPassword = useCallback(
    (email) => api('Account/ForgotPassword', { data: { email } }),
    [],
  )

  const resetPassword = useCallback(
    ({ email, code, password }) =>
      api('Account/ResetPassword', { data: { email, password, code } }),
    [],
  )

  const sendEmailVerification = useCallback(
    (email) => api(`Account/ReSendConfirmationCode?email=${email}`),
    [],
  )

  const changePassword = useCallback(
    ({ oldPassword, newPassword }) =>
      api('Account/ChangePassword', { data: { oldPassword, newPassword } }),
    [],
  )

  const logout = useCallback(() => {
    window.localStorage.clear()
    window.sessionStorage.clear()
    setData(null)
    navigate('/login')
  }, [navigate, setData])

  const setAccountStatusToPending = useCallback(() => {
    setData({ ...account, status: accountStatus.new.id })
  }, [account, setData])

  useEffect(() => {
    run(getAccountData())
  }, [run])

  const value = useMemo(
    () => ({
      register,
      validateAccount,
      login,
      forgetPassword,
      resetPassword,
      changePassword,
      sendEmailVerification,
      logout,
      setAccountStatusToPending,
      account,
      isAccountUnvalid: account?.status !== accountStatus.valid.id,
    }),
    [
      account,
      changePassword,
      forgetPassword,
      login,
      logout,
      register,
      resetPassword,
      sendEmailVerification,
      setAccountStatusToPending,
      validateAccount,
    ],
  )

  if (isLoading || isIdle) {
    return <FullPageSpinner />
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />
  }

  return <AuthContext.Provider value={value} {...props} />
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export const errors = {
  DuplicateUserName: 'Cet email existe déjà!',
  InvalidCode: 'Code invalide!',
  UserNotfound: 'Utilisateur non trouvé!',
  InvalidCredentials: "L'email ou le mot de passe est incorrect!",
  UnconfirmedEmail: 'Email non confirmé!',
  UnvalidCode: 'Code invalide!',
  PasswordMismatch: 'Mot de passe incorrect!',
  EmailNotSent: 'EmailNotSent',
  requestNotFound: 'Request not found',
  ScheduleNotFound: 'ScheduleNotFound',
  NotAllowed: 'NotAllowed',
  'duplicate email': 'duplicate email',
}

export default function errorsHandler({ errorCode } = {}) {
  return errorCode
    ? errors[errorCode] || 'Unhandled error!'
    : 'Something went wrong try again later!'
}

import { Box, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'

export default function FullPageSpinner({ withTopBar = false }) {
  return (
    <Box
      sx={{
        height: withTopBar ? 'calc(100vh - 56px)' : '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  )
}

FullPageSpinner.propTypes = { withTopBar: PropTypes.bool }

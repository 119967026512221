import { BrowserRouter, useLocation, Navigate } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { lazy, Suspense } from 'react'
import theme from './utils/theme'
import { AuthProvider, roles, useAuth } from './modules/security/AuthProvider'
import AlertProvider from './AlertProvider'
import FullPageErrorFallback from './components/FullPageErrorFallback'
import FullPageSpinner from './components/FullPageSpinner'

const ConsultantApp = lazy(() =>
  import(
    /* webpackChunkName: "consultant-app" */ /* webpackPrefetch: true */ './modules/consultant'
  ),
)
const ClientApp = lazy(() =>
  import(/* webpackChunkName: "client-app" */ './modules/client'),
)
const EmployeeApp = lazy(() =>
  import(/* webpackChunkName: "employee-app" */ './EmployeeApp'),
)
const AdminApp = lazy(() =>
  import(/* webpackChunkName: "admin-app" */ './modules/admin'),
)
const UnauthenticatedApp = lazy(() =>
  import(/* webpackChunkName: "unauthenticated-app" */ './modules/security'),
)

function ModulesSwitcher() {
  const { account } = useAuth()

  const { state } = useLocation()

  const getApp = () => {
    if (account && state?.fromLoginTo) {
      return <Navigate to={state.fromLoginTo} />
    }

    if (account?.type === roles.consultant) {
      return <ConsultantApp />
    }

    if (account?.type === roles.admin) {
      return <AdminApp />
    }

    if (account?.type === roles.client) {
      return <ClientApp />
    }

    if (account?.type === roles.employee) {
      return <EmployeeApp />
    }

    return <UnauthenticatedApp />
  }

  return <Suspense fallback={<FullPageSpinner />}>{getApp()}</Suspense>
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AlertProvider>
          <AuthProvider>
            <ErrorBoundary FallbackComponent={FullPageErrorFallback}>
              <ModulesSwitcher />
            </ErrorBoundary>
          </AuthProvider>
        </AlertProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App

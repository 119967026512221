import errorsHandler from './errors-handler'

const apiURL = process.env.REACT_APP_API_URL

const TOKENS_STORAGE_KEY = 'tokens'

export const setLocalStorageTokens = ({ accessToken, refreshToken }) =>
  window.localStorage.setItem(
    TOKENS_STORAGE_KEY,
    JSON.stringify({ accessToken, refreshToken }),
  )

export const getLocalStorageTokens = () => {
  const tokens = window.localStorage.getItem(TOKENS_STORAGE_KEY)
  return tokens ? JSON.parse(tokens) : null
}

function encodeUrl(url) {
  const splitedUrl = url.split('?')

  if (splitedUrl.length !== 2) {
    return url
  }

  const params = splitedUrl[1].split('&').map((item) => {
    const splitedItem = item.split('=')
    return `${splitedItem[0]}=${window.encodeURIComponent(splitedItem[1])}`
  })

  return `${splitedUrl[0]}?${params.join('&')}`
}

export default async function api(
  endpoint,
  { data, headers: customHeaders, isPrivate = true, ...customConfig } = {},
) {
  const { accessToken, refreshToken } = getLocalStorageTokens() || {}

  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization:
        isPrivate && accessToken ? `Bearer ${accessToken}` : undefined,
      'Content-Type': data ? 'application/json' : undefined,
      ...customHeaders,
    },
    ...customConfig,
  }

  const url = encodeUrl(`${apiURL}/${endpoint}`)

  return window.fetch(url, config).then(async (response) => {
    if (response.status === 401) {
      // refresh token
      return api('Token/Refresh', {
        data: { accessToken, refreshToken },
        isPrivate: false,
      })
        .then(async (refreshedData) => {
          setLocalStorageTokens(refreshedData)
          return api(endpoint, {
            ...config,
            headers: {
              ...config.headers,
              Authorization: isPrivate
                ? `Bearer ${refreshedData.accessToken}`
                : undefined,
            },
          })
        })
        .catch(() => {
          // clear storage
          window.localStorage.clear()
          window.sessionStorage.clear()
          // refresh the page
          window.location.assign(window.location)
          return Promise.reject(new Error('Please re-authenticate.'))
        })
    }

    const contentType = response.headers.get('content-type')

    let responseData =
      contentType && contentType.indexOf('application/json') !== -1
        ? await response.json()
        : await response.text()

    if (response.headers.has('x-total-count')) {
      responseData = {
        data: responseData,
        count: Number(response.headers.get('x-total-count')),
      }
    }

    return response.ok
      ? responseData
      : Promise.reject(new Error(errorsHandler(responseData)))
  })
}

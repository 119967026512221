import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { Alert, Snackbar } from '@mui/material'

const AlertContext = createContext()
AlertContext.displayName = 'AlertContext'

export default function AlertProvider({ children }) {
  const [state, setState] = useState({
    severity: 'error',
    autoHideDuration: 6000,
    open: false,
    message: '',
    horizontal: 'center',
    vertical: 'bottom',
    variant: 'filled',
  })

  const closeAlert = useCallback(
    () => setState((prev) => ({ ...prev, open: false })),
    [],
  )

  const showAlert = useCallback(
    (
      message = '',
      severity = 'error',
      {
        autoHideDuration = 6000,
        vertical = 'bottom',
        horizontal = 'center',
        variant = 'filled',
      } = {},
    ) =>
      setState((prev) => ({
        ...prev,
        message,
        severity,
        autoHideDuration,
        vertical,
        horizontal,
        variant,
        open: true,
      })),
    [],
  )

  const {
    severity,
    autoHideDuration,
    open,
    message,
    horizontal,
    vertical,
    variant,
  } = state

  const value = useMemo(
    () => ({ showAlert, closeAlert }),
    [closeAlert, showAlert],
  )

  return (
    <AlertContext.Provider value={value}>
      {children}
      <Snackbar
        open={open}
        anchorOrigin={{ horizontal, vertical }}
        autoHideDuration={autoHideDuration}
        onClose={closeAlert}
        sx={{
          width: 'fit-content',
          mx: 'auto',
          maxWidth: 'calc(100vw - 32px)',
        }}
      >
        <Alert
          onClose={closeAlert}
          variant={variant}
          severity={severity === 'default' ? 'info' : severity}
          sx={{
            width: '100%',
            ...(severity === 'success' && { backgroundColor: 'primary.main' }),
            ...(severity === 'default' && {
              backgroundColor: 'rgb(50,50,50)',
            }),
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  )
}

AlertProvider.propTypes = { children: PropTypes.node }

export function useAlert() {
  const context = useContext(AlertContext)
  if (context === undefined) {
    throw new Error(`useAlert must be used within a AlertProvider`)
  }
  return context
}

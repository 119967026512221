import { Box } from '@mui/material'
import PropTypes from 'prop-types'

export default function FullPageErrorFallback({ error }) {
  return (
    <Box
      role="alert"
      sx={{
        color: 'error.main',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p>
        Euh oh... Il y a un problème. Essayez d&apos;actualiser
        l&apos;application.
      </p>
      <pre>{error.message}</pre>
    </Box>
  )
}

FullPageErrorFallback.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string.isRequired }).isRequired,
}
